<template>
  <page style='background: #fff'>
    <div>


       <div style="min-height: 94vh">
            <div class="topPanel_bg"></div>
            <div class="topPanel">
              <img class='logo transform_r_c' src="../../assets/post_logo.png" alt="">
            </div>
            <div style="height: 50px"></div>
            


               <van-tabs v-model="active" @click="onTabClick" color="#4F92E6">
                <van-tab title="账号登录">

                      <van-field
              class='f_flex f_a_c telField '
              v-model="phone"
              type="tel"
              label-width='24'
              placeholder="请输入手机号">
              <div slot="label" class="f_flex f_a_c f_j_c">
                <img class="labelIcon" src="../../assets/login_phone.png" alt="">
              </div>
            </van-field>
            <van-field
              v-model="pwd"
              label-width='24'
              left-icon="../../assets/login_lock.png"
              center
              clearable
              placeholder="短信验证码"
              class="vertical-code f_flex f_a_c telField"
            >
              <template slot="left-icon" class="f_flex f_a_c f_j_c">
                <img class="labelIcon" src="../../assets/login_lock.png" alt="">
              </template>
              <template slot="button">
                <van-button size="small" type="primary" @click="getVerCode">{{!countDown?'获取验证码':`${(10>countDown)?'0'+countDown:countDown}秒`}}</van-button>
              </template>
            </van-field>

            <!-- <van-field class='f_flex f_a_c telField' v-model="pwd" :type="pwdType" label-width='24' placeholder="短信验证码">
              <div slot="label" class="f_flex f_a_c f_j_c">
                <img class="labelIcon" src="../../assets/login_lock.png" alt="">
              </div>
              <template #button>
                <toggle-pwd :inputType="pwdType" @change='changeInputType'></toggle-pwd>
              </template>
            </van-field> -->
            <div class=" f_flex f_a_c f_j_sb margin_auto pwdTip tip-text">
              <van-checkbox
                v-model="isSelected"
                class="checkbox"
                shape="square"></van-checkbox>我已阅读并同意 <a class="theme_c" href="https://res.ifeitie.com/page/resource/protocol/userAgree.html">《用户服务协议》</a>及<a href="https://res.ifeitie.com/page/resource/protocol/privacy.html" class="theme_c">《隐私政策》</a>
            </div>

            <!-- <div class="theme_c f_flex f_a_c f_j_sb margin_auto pwdTip">
              <div @click='goRegister'>快速注册</div>
              <div @click='goForget'>忘记密码</div>
            </div> -->
            <div style="height: 50px"></div>

            <button class="login_btn theme_bg margin_auto btn_radius" @click="goLogin">登录/注册</button>

                
                </van-tab>
                <van-tab title="扫码登录">

      <div class="f_flex f_j_c title">请使用飞贴APP扫码登录</div> 

                


                  <div class="f_flex f_j_c" v-if='imgUrl'>
                       <div class="qrcode-main">
                         <div class="qrcode-error-mask" v-if='qrError'>
                                
                             </div>
                           <div class="qrcode-error" v-if='qrError'>
                             
                              <p class="err-cont">二维码已失效</p>
                              <a class="qr_refresh_btn theme_bg margin_auto" @click="getCode">刷新</a>             
                            </div>
                           

                            <div class="qrcode"><img class="qrcode"  :src="imgUrl" alt="" ></div>
                  
                        
                       </div>
                
                  </div>
                     <div class="f_flex f_j_c subTitle">打开飞贴-设置-右上角扫一扫</div>


                     <div class="f_flex f_j_e theme_c bottomSpan" @click="active=0">注册登录-></div>
                </van-tab>
          
              </van-tabs>
          </div>

     
      

      <!-- <div v-if='showThirdLoginType'>
        <div class="f_flex f_a_c f_j_c">
          <div class="btm-line f_g1"></div>
          <div class="tip" style="margin-top: 0">第三方账号登录</div>
          <div class="btm-line f_g1"></div>
        </div>
        <div class="f_flex f_a_c f_j_c" style="margin-top: 15px; margin-bottom: 29px">
          <svg t="1603975599610" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3789" width="40" height="40"><path d="M512 1.896C229.452 1.896 0 229.452 0 512s229.452 510.104 512 510.104S1022.104 794.548 1022.104 512 794.548 1.896 512 1.896z m-91.022 629.57c-26.548 0-49.304-5.688-75.852-11.377l-75.852 37.926 22.756-66.37c-54.993-37.926-87.23-87.23-87.23-147.912 0-104.296 98.607-185.837 218.074-185.837 108.089 0 201.007 64.474 219.97 153.6-7.585 0-13.274-1.896-20.859-1.896-104.296 0-185.837 77.748-185.837 172.563 0 15.17 1.896 30.34 7.585 45.511-7.585 3.793-15.17 3.793-22.755 3.793z m322.37 77.749l17.067 54.992-58.785-34.133c-22.756 5.689-43.615 11.378-66.37 11.378-104.297 0-185.838-70.163-185.838-157.393S530.963 424.77 635.26 424.77c98.608 0 185.837 70.163 185.837 159.29 0 47.407-32.237 91.021-77.748 125.155z" fill="#46BB36" p-id="3790"></path><path d="M318.578 379.26c0 17.066 13.274 30.34 30.34 30.34s30.341-13.274 30.341-30.34-13.274-30.341-30.34-30.341-30.341 13.274-30.341 30.34z m235.14 159.288c0 13.274 11.378 24.652 24.652 24.652 13.274 0 24.652-11.378 24.652-24.652 0-13.274-11.378-24.652-24.652-24.652-13.274-1.896-24.651 9.482-24.651 24.652z m-81.54-159.289c0 17.067 13.274 30.341 30.34 30.341 17.067 0 30.341-13.274 30.341-30.34 0-17.067-13.274-30.341-30.34-30.341-17.067 0-30.341 13.274-30.341 30.34zM675.08 538.55c0 13.273 11.378 24.651 24.652 24.651 13.274 0 24.652-11.378 24.652-24.652 0-13.274-11.378-24.652-24.652-24.652-13.274-1.896-24.652 9.482-24.652 24.652z" fill="#46BB36" p-id="3791"></path></svg>
        </div>
      </div> -->
      <!-- <div class="f_flex f_a_c f_j_c">
        <div class="protocol_txt" style="color: #9B9B9B;">登录即代表同意 </div>
        <div class="protocol_txt theme_c">《用户服务协议》</div>
        <div class="protocol_txt" style="color: #9B9B9B;">及</div>
        <div class="protocol_txt theme_c">《隐私政策》</div>
      </div> -->
    </div>
  </page>
</template>

<script>
import { Field } from 'vant';
// import togglePwd from './togglePwd.vue'
import apiLogin from '@/api/user.js'
import storageMixins from '@/common/mixins/storage.js'
import getAuth from '@/common/mixins/getAuth.js'
import QRCode from 'qrcode'


export default {
  data: ()=> ({
    phone: '',
    pwd: '',
    pwdType: 'password',
    logo: '',
    showThirdLoginType: false,
    isSelected: true,
    countDown:0,
    timer:null,
    qrTimer:null,
    active: 0,
    imgUrl: '',
    qrcode: '',
    isMobile: '',
    qrCodeCountDown: 0,
    qrError: false,
  }),
  mixins: [storageMixins, getAuth],
  components: {
    'van-field': Field
  },
  methods: {

    onTabClick(name) {
      if (name == 1) {
        this.getCode()


         
              
      } else {
        clearInterval(this.qrTimer)
        this.qrTimer = null;
      }
    },

    getCode() {
     
      apiLogin.generateQrCode({
          
        }).then(res => {
          if (res.data) {
            this.qrcode = res.data.qrcode
            QRCode.toDataURL(res.data.qrcode,
              {
                  color: {
                  dark: '#000', // 二维码背景颜色
                  // light: '#000' // 二维码前景颜色
                },
              }
            )
              .then(url => {
                // console.log(url)
                this.imgUrl = url
                this.qrCodeCountDown = 0
                this.qrError = false
                if (!this.qrTimer) {
                  this.qrTimer = setInterval(()=>{
                      if (this.qrcode && this.qrCodeCountDown < 120) {
                        this.qrCodeCountDown += 5;
                        console.log("开始轮询", this.qrcode)
                        apiLogin.getLoginInfo({
                            qrcode: this.qrcode
                          }).then(res => {
                              if (res.data) {
                                //登录成功
                                let JSESSIONID = res.data.JSESSIONID
                              

                                if (JSESSIONID) {
                                  console.log("登录成功", JSESSIONID)
                                    this.$global.setItem({
                                        JSESSIONID: JSESSIONID,
                                        
                                        })
                                    clearInterval(this.qrTimer)
                                    this.qrTimer = null;
                                    this.redirectToUrl()
                                }

                              } else {
                                console.log("登录失败")
                              }
                            })
                      } else {
                        this.qrError = true
                      }
                  },5000)
                }
              })
              .catch(err => {
                console.error(err)
              })

          } else {
            this.imgUrl = ''
          }
        })
    },

    getVerCode(){
      if(this.phone.match(/^1\d{10}$/)){
        if (this.countDown) {
          return
        }
        apiLogin.getVerCode({
          phone: this.phone,
        }).then(res => {
          if (res.data) {
            this.$toast('发送验证码成功')
            this.countDown = 59
            this.timer = setInterval(()=>{
              this.countDown--
              console.log("countDown ---")
              if (this.countDown===0) {
                clearInterval(this.timer)
              }
            },1000)

          } else {
            this.$toast(res.errorMsg)
          }
        })
      } else {
        this.$toast('手机号码不正确')
      }

    },
    goLogin() {
      if(!this.isSelected){
        this.$toast('请阅读并同意协议')
        return;
      }
      if (this.phone.match(/^1\d{10}$/)) {
        if (this.phone && this.pwd) {
          apiLogin.login({
            phone: this.phone,
            verifyCode: this.pwd
          }).then(res => {
            if (res.data) {
              this.saveLoginInfo(res.data)
              this.phone = ''
              this.pwd = ''
              //this.$router.push('/home')
              this.redirectToUrl()
              if (res.data.userNameFillFlag === 1) {
                this.$dialog.confirm({title: '是否先完善资料'}).then(()=> {
                  this.$router.push('/editName')
                }).catch(()=> {})
              }
            } else {
              this.$toast(res.errorMsg)
            }
          })
        }
      } else {
        this.$toast('手机号码不正确')
      }
    },
    changeInputType(e) {
      this.pwdType = e
    },
    goRegister() {
      this.$router.push('/register')
    },
    goForget() {
      this.$router.push('/resetPwd')
    },
    redirectToUrl() {
      let redirectUrl = this.$route.query.redirect
      console.log("跳转URL：", redirectUrl)
      if (redirectUrl && redirectUrl != "/login") {
          this.$router.push({path:redirectUrl});
      } else {
        this.$router.push({path:"home"});
      }         
      
    }
  },
  created() {
    this.logo = this.$global.getItem('appConfig').aboutUsIcon.iconUrl
    this.showThirdLoginType = this.$global.ua().isWX

    this.isMobile = this.$global.ua ().isTOUCH

    if (this.isMobile) {
      this.active = 0;
    } else {
      this.active = 1;
      this.getCode()
    }
  },
}
</script>

<style lang="less" scoped>
@bgHeight: 217px;

.qrcode {
  width: 200px;
  height: 200px;
  border-radius: 18px;
  box-sizing: border-box;
  position: relative;
}

.qrcode-main {
   position: relative;
    color: #fbfbfb;
  
}

.qr_refresh_btn {
  width: 80px;
  position: absolute;
  left:50px;
  top: 85px;
  line-height: 30px;
  text-align: center;
}

.qrcode-error {
    display: block;
    font-size: 14px;
    width: 100%;
    margin-top: 20px;
    position: absolute;
    z-index: 10;
    left:10px;
}
.qrcode-error-mask {
    display: block;
    position: absolute;
    width: 160px;
    background: #000;
    filter: alpha(opacity=60);
    -moz-opacity: .6;
    opacity: .6;
    height: 160px;
    left: 20px;
    margin-top: 20px;
    z-index: 9;
    top: 0;
    text-align: center;
    line-height: 100px;
}

.err-cont {
    position: absolute;
    left: 50px;
    top: 55px;
    width: 100%;
    color: #fbfbfb;
    z-index: 19;
    font-weight: 700;
}
 
.title {
    font-size: 16px;
    color: #2D2D2D;
    font-weight: 400;
    line-height: 22px;
    
}

.subTitle {
    font-size: 13px;
    line-height: 18px;
    color: #9B9B9B;
}

.bottomSpan {
    font-size: 14px;
    margin-right: 200px;
}
.topPanel_bg {
  background: linear-gradient(90deg, #4AB2FF 0, #5382EE 110%);
  height: @bgHeight;
  border-radius: 0 0 50% 50%;
  position: absolute;
  width: 110%;
  left: -5%;
  top: 0;
}

.topPanel {
  position: relative;
  height: @bgHeight;
  width: 100%;
  .logo {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 18px;
    // box-shadow: 0 0 15px #c1c1c1;
    // background: #fff;
    top: 137px;
    box-sizing: border-box;
  }
}
.telField {
  padding: 0;
  margin: 0 18px;
  width: auto;
  background: transparent;
  height: 35px;
  // border-bottom: 1px solid #ccc;
  font-size: 14px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.labelIcon {
  width: 14px;
}
.pwdTip {
  font-size: 12px;
  // width: 70%;
  margin:0 18px;
  text-align: center;
}
.login_btn {
  width: 70%;
  height: 40px;
  display: block;
}
.tip {
  font-size: 12px;
  color: #BABABA;
  text-align: center;
  margin-top: 19px;
}
.btm-line {
  border-bottom: 1px solid #eee;
  margin: 0 15px;
}

.protocol_txt {
  font-size: 11px;
}
.tip-text{
  font-size: 11px;
  color: #9B9B9B;
  justify-content: flex-start;
  margin-top: 20px;
  /deep/.van-checkbox__icon .van-icon{
    width: 1em;
    height: 1em;
    line-height: 1em;
    font-size: 0.7em;
  }
  /deep/.van-checkbox__icon{
    display: flex;
    align-items: center;
  }
}
.checkbox{
  margin-right: 10px;
}
.vertical-code{
  /deep/.van-field__left-icon{
    width: 24px;
    text-align: center;
    margin-right: 12px;
  }
  /deep/.van-button--primary{
    background-color: transparent;
    border-color: transparent;
  }
  /deep/.van-button__text{
    color: #4F92E6;
  }
}
</style>
